import React from "react";
import hamburgerIcon from "../Assets/Images/hamburgerIcon.svg";
import { useNavigate } from "react-router-dom";
import Hamburger from "./Hamburger";
import logoMemshots from "../Assets/logoMemshots.svg";

function Navbar() {
  const navigate = useNavigate();
  const url = window.location.href
  const path = url.split("/").pop()

  return (
    <div className="border-b-[1px] border-[#8B34FD1A] px-[5.26%] lg:px-[120px] pt-[30px] pb-[12px] lg:pb-[20px]  flex items-center justify-between  bg-white w-full sticky top-0 z-50">
      <div className="flex gap-[16px]">
        {/* <div className="mobileOnly">
          <Hamburger />
        </div> */}
        <div
          onClick={() => navigate("/")}
          className="text-[#161616] cursor-pointer hover:opacity-[0.8]"
        >
          {/* <p className="font-bold text-[20px] xl:text-[22px]">
            SHOOT<span className="font-normal">MATES</span>
          </p> */}
          <img
            src={logoMemshots}
            alt=""
            className="w-auto h-[54px] "
          />
        </div>
      </div>
      <div className="text-[#161616] text-[16px] flex gap-[40px] justify-end items-center w-full px-[10.56%] desktopOnly">
        {/* <a href="https://crm.shootmates.com" target="_blank">
          <button className="font_medium_18 text-[#161616] hover:text-[#8B34FD]">
            Go to CRM
          </button>
        </a> */}
        <button
          onClick={() => navigate("/")}
          className={`font_medium_18 hover:text-[#8B34FD] ${path === '' ? 'text-[#8B34FD]' : 'text-[#161616]'}`}
        >
          Tools
        </button>
        {/* <button
          onClick={() => navigate("/jobs")}
          className={`font_medium_18 hover:text-[#8B34FD] ${path === 'jobs' ? 'text-[#8B34FD]' : 'text-[#161616]'}`}
        >
          Jobs
        </button> */}
        {/* <button
          onClick={() => navigate("/community")}
          className={`font_medium_18 hover:text-[#8B34FD] ${path === 'community' ? 'text-[#8B34FD]' : 'text-[#161616]'}`}
        >
          Community
        </button> */}
        {/* <button
          onClick={() => navigate("/tutorials")}
          className={`font_medium_18  hover:text-[#8B34FD] ${path === 'tutorials' ? 'text-[#8B34FD]' : 'text-[#161616]'}`}
        >
          Tutorials
        </button> */}
        <button
          onClick={() => navigate("/pricing")}
          className={`font_medium_18 hover:text-[#8B34FD] ${path === 'pricing' ? 'text-[#8B34FD]' : 'text-[#161616]'}`}
        >
          Pricing
        </button>
      </div>
      <div className="flex gap-[10px]">
        {/* <a href="https://calendly.com/shootmates/30min" target="_blank">
          <button className="desktopOnly whitespace-nowrap px-[24px] py-[12px] rounded-[30px] border-[1px] border-[#CACACA] bg-[#FFF] hover:opacity-[0.8] font_medium_16 text-[#000] hover:text-[#8B34FD]">
            Request a demo
          </button>
        </a> */}
        {/* <a href="https://crm.shootmates.com" target="_blank">
          <button className="desktopOnly whitespace-nowrap px-[24px] py-[12px] rounded-[30px] bg-[#8B34FD] hover:bg-[#F6F6F6] font_medium_16 text-[#F6F6F6] hover:text-[#8B34FD]">
            Free trial
          </button>
        </a> */}
        {/* to pricing only from tools. other pages to tools.when home page comes,change path */}
        <button
          onClick={() => {
            { path === "" ? navigate("/pricing") : navigate("/") }
          }}
          className="mobileOnly whitespace-nowrap px-[24px] py-[10px] rounded-[30px] border-[1px]  bg-[#8B34FD] hover:opacity-[0.8] ">
          <p className="font_medium_16_14 text-[#F6F6F6]">{path === "" ? "Pricing" : "Smart Album Tools"}</p>
        </button>
      </div>
    </div>
  );
}

export default Navbar;
