import React, { useEffect, useRef, useState } from "react";
import hamburgerIcon from "../Assets/Images/hamburgerIcon.svg";
import { useNavigate } from "react-router-dom";

function Hamburger() {
  const [dropdown, setDropdown] = useState(false);
  const modalRef = useRef();
  const navigate = useNavigate()

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the modal is open and the clicked target is not within the modal,
      //then close the modal
      if (
        dropdown &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);

  return (
    <div>
      <div className="relative cursor-pointer" ref={modalRef}>
        <img
          onClick={(e) => {
            setDropdown((prevState) => {
              e.stopPropagation()
              return (
                !prevState
              )
            });
          }}
          src={hamburgerIcon} alt="" />
        {dropdown && (
          <div className="absolute top-[20px] left-[20px] z-10 flex flex-col bg-white rounded-[12px] p-[20px] gap-[20px] whitespace-nowrap">
            <button className="border-b-[1px] border-[#c9c9c9] font_medium_16 text-[#161616]">Tutorials</button>
            <button className="border-b-[1px] border-[#c9c9c9] font_medium_16 text-[#161616]">Go to CRM</button>
            <button
              className="border-b-[1px] border-[#c9c9c9] font_medium_16 text-[#161616]"
              onClick={() => navigate("/pricing")}
            >Pricing</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Hamburger;
