import React, { useEffect } from "react";
import linkedInIcon from "../Assets/Images/linkedinLogo.svg";
import instaIcon from "../Assets/Images/instaLogo.svg";
import { useNavigate } from "react-router-dom";
import logoMemshots from "../Assets/logoMemshots.svg";

function Footer() {
  const navigate = useNavigate();
  const url = window.location.href;
  const path = url.split("/").pop();

  return (
    <div className=" w-full h-full px-[8.33%] mb-[16px] lg:mb-[40px]">
      <div className="w-full flex flex-col lg:flex-row items-center justify-center lg:justify-between mb-[24px] lg:mb-[115px]">
        <div
          onClick={() => {
            navigate("/");
          }}
          className="text-[#161616] cursor-pointer hover:opacity-[0.8]"
        >
          {/* <p className="font-bold text-[22px]">
            SHOOT<span className="font-normal">MATES</span>
          </p> */}

          <img
            src={logoMemshots}
            alt=""
            className="w-auto h-[54px]"
          />
        </div>
        <div className=" text-[13px] font-semibold my-[16px] lg:my-0  gap-y-[12px]  gap-[20px] flex justify-center lg:justify-end lg:items-end w-full px-0 lg:pl-[10.56%]">
          <button
            onClick={() => navigate("/")}
            className={`${
              path === "" ? "text-[#8B34FD]" : "text-[#161616]"
            }  hover:text-[#8B34FD]"`}
          >
            Tools
          </button>
          {/* <button
            onClick={() => navigate("/jobs")}
            className={`${
              path === "jobs" ? "text-[#8B34FD]" : "text-[#161616]"
            }  hover:text-[#8B34FD]"`}
          >
            Jobs
          </button>
          <button
            onClick={() => navigate("/community")}
            className={`${
              path === "community" ? "text-[#8B34FD]" : "text-[#161616]"
            }  hover:text-[#8B34FD]"`}
          >
            Community
          </button>
          <button
            onClick={() => navigate("/tutorials")}
            className={`${
              path === "tutorials" ? "text-[#8B34FD]" : "text-[#161616]"
            }  hover:text-[#8B34FD]"`}
          >
            Tutorials
          </button> */}
          {/* <a href="https://crm.shootmates.com" target="_blank">
            <button className={`${path === '' ? 'text-[#8B34FD]' : 'text-[#161616]'}>Go to CRM</button>
          </a> */}
          <button
            onClick={() => navigate("/pricing")}
            className={`${
              path === "pricing" ? "text-[#8B34FD]" : "text-[#161616]"
            }  hover:text-[#8B34FD]"`}
          >
            Pricing
          </button>
          {/* <button>Support</button> */}
        </div>
        {/* <div className="flex gap-[12px]">
          <img src={linkedInIcon} alt="" />
          <img src={instaIcon} alt="" />
        </div> */}
      </div>
      <div className="text-[#787878] text-[13px] leading-[18px] flex justify-between  flex-col lg:flex-row gap-[24px] lg:gap-0 items-center">
        <div className="flex flex-wrap gap-[16px] lg:gap-[24px] w-full">
          <p className="">© 2024 Memshots</p>
          <p
            onClick={() => {
              navigate("/tandc");
            }}
            className="cursor-pointer"
          >
            Terms & Conditions
          </p>
          <p
            onClick={() => {
              navigate("/privacypolicy");
            }}
            className="cursor-pointer"
          >
            Privacy Policy
          </p>
          <p
            onClick={() => {
              navigate("/refund");
            }}
            className="cursor-pointer"
          >
            Cancellation and Refund Policy
          </p>
          <p
            onClick={() => {
              navigate("/shipping");
            }}
            className="cursor-pointer"
          >
            Shipping and Delivery Policy
          </p>
        </div>
        <div className="text-center">
          <p>+91 9986619796</p>
          <p>hello@memshots.com</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
