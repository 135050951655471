import React from "react";
import { useNavigate } from "react-router-dom";
import pricingDefault from "../Assets/Images/BottomNavbarIcons/pricingDefault.svg";
import pricingSelected from "../Assets/Images/BottomNavbarIcons/pricingSelected.svg";
import toolsDefault from "../Assets/Images/BottomNavbarIcons/toolsDefault.svg";
import toolsSelected from "../Assets/Images/BottomNavbarIcons/toolsSelected.svg";

function NavbarBottom() {
  const navigate = useNavigate();
  const url = window.location.href;
  const path = url.split("/").pop();

  return (
    <div className="mobileOnly border-t-[1px] border-[#8B34FD1A] py-[8px] px-[5.26%]  bg-white w-full fixed bottom-0 z-50">
      <div className="justify-center  gap-[50px] items-center flex">
        {/* <div
          onClick={() => navigate("/")}
          className={`pt-[3px] flex flex-col gap-[1px] items-center text-[#000] ${
            path == "" && "border-t-[2px] border-[#8B34FD] text-[#8B34FD]"
          } `}
        >
          <img src={home} alt="" />
          <p className=" font_medium_18">H</p>
          <p className=" font_medium_12">Home</p>
        </div> */}
        {/* <div
        onClick={() => navigate("/jobs")}
          className={`pt-[3px] flex flex-col gap-[1px] items-center text-[#000] ${
            path == "jobs" && "border-t-[2px] border-[#8B34FD] text-[#8B34FD]"
          } `}
        >
          <img src={home} alt="" />
          <p className=" font_medium_18">J</p>
          <p className=" font_medium_12">Jobs</p>
        </div> */}
        <div
          onClick={() => navigate("/")}
          className={`pt-[3px] flex flex-col gap-[1px] items-center text-[#000] ${
            path === "" && "border-t-[2px] border-[#8B34FD] text-[#8B34FD]"
          } `}
        >
          {path === "" ? (
            <img src={toolsSelected} alt="" />
          ) : (
            <img src={toolsDefault} alt="" />
          )}
          {/* <p className=" font_medium_18">T</p> */}
          <p className=" font_medium_12">Tools</p>
        </div>
        {/* <div
         onClick={() => {
          navigate("/community")
        }}
          className={`pt-[3px] flex flex-col gap-[1px] items-center text-[#000] ${
            path == "community" &&
            "border-t-[2px] border-[#8B34FD] text-[#8B34FD]"
          } `}
        >
          <img src={home} alt="" />
          <p className=" font_medium_18">C</p>
          <p className=" font_medium_12">Community</p>
        </div> */}
        {/* <div
          onClick={() => {
            navigate("/tutorials")
          }}
          className={`pt-[3px] flex flex-col gap-[1px] items-center text-[#000] ${
            path == "tutorials" &&
            "border-t-[2px] border-[#8B34FD] text-[#8B34FD]"
          } `}
        >
          <img src={home} alt="" />
          <p className=" font_medium_18">T</p>
          <p className=" font_medium_12">Tutorials</p>
        </div> */}
        <div
          onClick={() => navigate("/pricing")}
          className={`pt-[3px] flex flex-col gap-[1px] items-center text-[#000] ${
            path === "pricing" &&
            "border-t-[2px] border-[#8B34FD] text-[#8B34FD]"
          } `}
        >
          {path === "pricing" ? (
            <img src={pricingSelected} alt="" />
          ) : (
            <img src={pricingDefault} alt="" />
          )}
          {/* <p className=" font_medium_18">P</p> */}
          <p className=" font_medium_12">Pricing</p>
        </div>
      </div>
    </div>
  );
}

export default NavbarBottom;
